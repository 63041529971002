import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface InsightsImageStyles {
  insightsImageContainer: Styles
  insightsImage: Styles
}

export const useStyles = createUseStyles<InsightsImageStyles>((theme) => {
  return {
    insightsImageContainer: {
      display: 'block',
      textAlign: 'center',
      margin: 'auto',
      marginTop: theme.calcUnit(20),
      marginBottom: theme.calcUnit(0),
      maxWidth: theme.calcUnit(406),
      minWidth: theme.calcUnit(335),
      // @ts-expect-error
      ...theme.breakpoint.down('xs', {
        margin: theme.calcUnit(10),
      }),
      // @ts-expect-error
      ...theme.breakpoint.up('lg', {
        marginTop: theme.calcUnit(32),
        marginBottom: theme.calcUnit(80),
        maxWidth: theme.calcUnit(420),
      }),
      // @ts-expect-error
      ...theme.breakpoint.up('xl', {
        marginTop: theme.calcUnit(40),
        marginBottom: theme.calcUnit(80),
      }),
    },
    insightsImage: {
      width: '100%',
      height: 'auto',
      borderRadius: theme.calcUnit(theme.borderRadius.roundedLg),
    },
  }
})
