import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface PffInsightsStyles {
  messagesContainer: Styles
  header: Styles
  textContainer: Styles
  icon: Styles
  videoContainer: Styles
  text: Styles
  pill: Styles
}

export const useStyles = createUseStyles<PffInsightsStyles, boolean>((theme) => {
  return {
    messagesContainer: (isDesktop) => ({
      marginBottom: isDesktop ? theme.calcUnit(theme.spacing.s2) : theme.calcUnit(theme.spacing.s4),
      marginTop: theme.calcUnit(theme.spacing.s3),
      width: '100%',
    }),
    header: (isDesktop) => ({
      fontFamily: isDesktop ? 'PFF Sans' : 'Roboto',
      fontWeight: isDesktop ? '600' : '700',
      fontStyle: 'normal',
      fontSize: isDesktop ? theme.calcUnit(28) : theme.calcUnit(18),
      lineHeight: isDesktop ? theme.calcUnit(32) : theme.calcUnit(21),
      paddingBottom: isDesktop ? theme.calcUnit(theme.spacing.s2) : theme.calcUnit(theme.spacing.s4),
      textTransform: isDesktop ? 'uppercase' : 'none',
      color: isDesktop ? theme.colors.webInverseTextColor : theme.colors.textColor,
    }),
    textContainer: (isDesktop) => ({
      lineHeight: theme.calcUnit(10),
      marginBottom: isDesktop ? theme.calcUnit(theme.spacing.s6) : theme.calcUnit(theme.spacing.s3),
    }),
    icon: {
      display: 'inline',
    },
    videoContainer: {
      width: '100%',
      position: 'relative',
    },
    text: (isDesktop) => ({
      fontFamily: 'Roboto',
      fontSize: isDesktop ? theme.calcUnit(16) : theme.calcUnit(11),
      lineHeight: isDesktop ? theme.calcUnit(16) : theme.calcUnit(10),
      fontWeight: isDesktop ? '500' : '400',
      paddingLeft: theme.calcUnit(theme.spacing.s2),
      color: isDesktop ? theme.colors.webInverseTextColor : theme.colors.textColor,
    }),
    pill: (isDesktop) => ({
      background: isDesktop ? theme.colors.webInverseTextColor : theme.colors.textColor,
      borderRadius: theme.borderRadius.roundedSm,
      padding: `${theme.calcUnit(isDesktop ? theme.spacing.s2 : theme.spacing.s1)} ${theme.calcUnit(theme.spacing.s1)}`,
      color: isDesktop ? theme.colors.textColor : theme.colors.webInverseTextColor,
      marginRight: theme.calcUnit(theme.spacing.s2),
    }),
  }
})
