import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface LayoutStyles {
  container: Styles
  leftPane: Styles
  rightPane: Styles
  mobileOnly: Styles
}

interface LayoutStyleProp {
  height: number
  isDesktop: boolean
}

export const useStyles = createUseStyles<LayoutStyles, LayoutStyleProp>((theme) => {
  return {
    container: {
      display: 'flex',
    },
    leftPane: (props) => ({
      width: '50%',
      height: theme.calcUnit(props.height) || '100%',
      display: props.isDesktop ? 'block' : 'none',
    }),
    rightPane: (props) => ({
      width: props.isDesktop ? '50%' : '100%',
      height: theme.calcUnit(props.height) || '100%',
      overflowY: 'scroll',
    }),
    mobileOnly: (props) => ({
      display: props.isDesktop ? 'none' : 'block',
    }),
  }
})
