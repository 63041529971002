import React, { useEffect } from 'react'
import Image from 'next/image'
import { useFeatureFlags } from '@pff-consumer/feature-flags'

import { useSubscribeAnalyticsEvent } from '../../../hooks/useSubscribAnalyticsEvent'
import { useStyles } from './insights-image.styles'
import insightsImageNoBullets from './insights-mobile-no-bullets.webp'
import insightsImageBullets from './insights-mobile-bullets.webp'
import insightsImageFMDS from './fantasy-mds-subscription-feature-hero.webp'

export const InsightsImage = () => {
  const styles = useStyles()
  const { sendSubscribeAnalyticsEvent } = useSubscribeAnalyticsEvent()
  const { getFeatureFlag } = useFeatureFlags()
  // the flag returns a value or '' and we use this to set the variant
  // defaulting to null is a hacky workaround to get around our LD implementation not bootstrapping flags
  const insightsImageFlagSrc = getFeatureFlag<string | null>('subscribe-insights-image', null)
  const isNoBullets = insightsImageFlagSrc && insightsImageFlagSrc.includes('no-bullets')
  const isBullets = insightsImageFlagSrc && !insightsImageFlagSrc.includes('no-bullets')
  const isFMDSImage = insightsImageFlagSrc && insightsImageFlagSrc.includes('fantasy-mds-subscription-feature-hero')

  let variant = ''
  if (insightsImageFlagSrc === '') {
    variant = 'Control'
  } else if (isNoBullets) {
    variant = 'Variant B - No Bullets'
  } else if (isBullets) {
    variant = 'Variant A - Bullets'
  } else if (isFMDSImage) {
    variant = 'Variant C - FMDS'
  }

  useEffect(() => {
    if (variant) {
      sendSubscribeAnalyticsEvent('Subscribe Experiment Viewed', {
        variant,
      })
    }
  }, [sendSubscribeAnalyticsEvent, variant])

  if (!insightsImageFlagSrc) {
    return null
  }

  if (isNoBullets) {
    return (
      <picture className={styles.insightsImageContainer}>
        <Image
          data-testid='pffInsights.insightsImageNoBullets'
          src={insightsImageNoBullets}
          className={styles.insightsImage}
          alt='Subscription Benefits'
        />
      </picture>
    )
  }

  if (isFMDSImage) {
    return (
      <picture className={styles.insightsImageContainer}>
        <Image
          data-testid='pffInsights.insightsImageNoBullets'
          src={insightsImageFMDS}
          className={styles.insightsImage}
          alt='Subscription Benefits'
        />
      </picture>
    )
  }

  return (
    <picture className={styles.insightsImageContainer}>
      <Image
        data-testid='pffInsights.insightsImageBullets'
        src={insightsImageBullets}
        className={styles.insightsImage}
        alt='Subscription Benefits'
      />
    </picture>
  )
}
