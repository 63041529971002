import React from 'react'
import { useBreakpoints } from '@pff-consumer/web-ui/styles'
import { Icon } from '@pff-consumer/web-ui/components/icon/icon'
import { SVGGroup } from '@pff-consumer/utils'
import { useFeatureFlags } from '@pff-consumer/feature-flags'
import { useStyles } from './pff-insights.styles'

const insightMessages: string[] = [
  'Sync Unlimited Fantasy Leagues',
  'Live Draft Assistant Powered by AI and PFF Exclusive Data',
  'Expert Strategy & Player Deep Dive Content',
  'Cheat Sheets, Rankings, and Projections',
  'WR-CB & OL-DL Matchups, PFF Player Grades, & Premium Stats 2.0',
  'Nathan Jahnke’s Rankings - #1 Most Accurate Last 70 Weeks',
]

export const PffInsights = () => {
  const { isMobile, isDesktop } = useBreakpoints()
  const styles = useStyles(isDesktop)
  const { getFeatureFlag } = useFeatureFlags()
  const insightsImageSrc = getFeatureFlag<string>('subscribe-insights-image', '')
  const showInsightsImage = isMobile && insightsImageSrc

  // we need to not render this component when showing the insights image since
  // the insights image has the same content, but higher in the DOM
  if (showInsightsImage) {
    return null
  }

  return (
    <div className={styles.messagesContainer}>
      <header className={styles.header}>
        AI POWERED LIVE DRAFT ASSISTANT
        <br />
        DATA TRUSTED BY ALL 32 NFL TEAMS
      </header>
      <br />

      {/* {draftKitMessages.map((message) => (
        <div
          key={message}
          className={styles.textContainer}
        >
          <Icon
            className={styles.icon}
            group={SVGGroup.Icons}
            name='checkmark-green'
            testID='feature-point-icon'
            width={12}
            height={12}
          />
          <span className={styles.text}>
            <span className={styles.pill}>DRAFT KIT </span> {message}
          </span>
        </div>
      ))} */}
      {/* {boldedMessages.map((message) => (
        <div
          key={message}
          className={styles.textContainer}
        >
          <Icon
            className={styles.icon}
            group={SVGGroup.Icons}
            name='checkmark-green'
            testID='feature-point-icon'
            width={12}
            height={12}
          />
          <span className={styles.text}>{message}</span>
        </div>
      ))} */}
      {insightMessages.map((message) => (
        <div
          key={message}
          className={styles.textContainer}
        >
          <Icon
            className={styles.icon}
            group={SVGGroup.Icons}
            name='checkmark-green'
            testID='feature-point-icon'
            width={12}
            height={12}
          />
          <span className={styles.text}>{message}</span>
        </div>
      ))}
    </div>
  )
}
